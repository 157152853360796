import axios from 'axios';
import React, { Component } from 'react';
// import FeatherIcon from 'feather-icons-react';
import ReactLoading from "react-loading";

class Login extends Component {
    state = {
        username: "",
        password: "",
        error : "",
        click : false
    }
     componentDidMount(){
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    login  = () => {
        this.setState({
            click : true,
            error : "",
        })
         axios.post('login', {
            username: this.state.username,
            password: this.state.password
        }).then((login) => {
            localStorage.setItem("user", JSON.stringify(login.data.data));
            window.location.replace(process.env.REACT_APP_EVIVESOMA)
        }).catch((error) => {
            window.localStorage.clear();

            this.setState({
                error: error.response.data.msg,
            });
        })
    }

    render() {

        return (
            <div>
                <div className="modal fade  pt-0" id="popupLogin" tabIndex="-1" aria-labelledby="popupLogin" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <section className="section register min-vh-50 p-0">
                            <div className="container">
                            <div className="mt-0">
                                <div className="p-0">
                    

                                <div className="card shadow rounded-0 pb-4">

                                    <div className="card-body">

                                    <div className="mt-0">
                                        <img className="d-block mx-auto" src="/logo192.png" alt="" width="100" height="80" />
                                        <h5 className="  text-center  fs-4">{ process.env.REACT_APP_NAME }</h5>
                                        {/* <div className="text-center small lead">Entrer votre Email et mot de passe pour vous authentifier</div> */}
                                    </div>

                                    <form className="row g-3 needs-validation">
                                        {this.state.error && (<div className="border border-red rounded rounded-3 border-1 text-center text-red py-1 px-2 text-small" style={{ color : "red" }}>{this.state.error}</div>)}
                                        <div className="col-12">
                                            <label htmlFor="yourUsername" className="form-label">Email ou téléphone</label>
                                            <div className="input-group has-validation">
                                                {/* <span className="input-group-text px-2" id="inputGroupPrepend"><FeatherIcon icon={"lock"} size={12}></FeatherIcon> </span> */}
                                                <input type="text" placeholder="Email ou téléphone"  name="username" onChange={this.handleInput} className="form-control form-control-sm border border-red rounded rounded-3 border-1" id="yourUsername" />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <label htmlFor="yourPassword" className="form-label">Mot de passe</label>
                                            <div className="input-group has-validation">
                                                {/* <span className="input-group-text px-2" id="inputGroupPrepend"><FeatherIcon icon={"key"} size={12}></FeatherIcon> </span> */}
                                                <input type="password" name="password" className="form-control form-control-sm border border-red rounded rounded-3 border-1 " onChange={this.handleInput} id="yourPassword" />
                                            </div>
                                        </div>
                                        
                                        <div className="col-12">
                                            <button className="btn btn-primary btn-sm w-100" type='button' onClick={() => this.login()}>
                                            <div className='d-flex justify-content-center'>
                                                {
                                                    this.state.click && (
                                                        !this.state.error && (
                                                            <ReactLoading type="spokes" color="white" height={20} width={15} />
                                                        )
                                                    )
                                                }
                                                <span>Connexion</span>
                                            </div> 
                                            </button>
                                        </div>
                                        <div className="col-12">
                                        <p className="small mb-0">Vous n'avez pas un compte? <a href="#"  className="text-decoration-none text-primary">Contactez le responsable</a></p>
                                        </div>
                                    </form>

                                    </div>
                                </div>

                                </div>
                            </div>
                            </div>
                        </section>
                            {/* <div className='text-end'>
                                <button type="submit" className="close btn btn-primary btn-sm" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div> */}
                    </div>
                </div>
                </div>
            </div>
        );
    }
}


export default Login;