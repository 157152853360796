import React, {Component} from "react";
import axios from "axios";

class Design7 extends Component{

  logout = (e) => {
    axios.post('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
    window.localStorage.clear();
    window.location.replace(process.env.REACT_APP_EVIVESOMA)
}

    render(){
      var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : false;
        return(
          <section className="pb-2 pb-lg-5">

          <div className="container">
            <div className="row border-top border-top-secondary pt-7">
              <div className="col-lg-3 col-md-6 mb-4 mb-md-6 mb-lg-0 mb-sm-2 text-center">
                <a className="navbar-brand btn btn-sm " style={{  color : "rgb(0, 0, 248)" }} href="/"><img src="/logo192.png" alt="logo evive" className="" height={39+"px"}  />{ process.env.REACT_APP_NAME } </a>
              </div>
              <div className="col-lg-3 col-md-6 mb-4 mb-lg-0 text-center">
                <p className="fs-2 mb-lg-4">Accessible</p>
                <ul className="list-unstyled mb-0">
                  <li className="mb-1"><a className="link-900 text-secondary text-decoration-none" aria-current="page" href="#support">Support</a></li>
                  <li className="mb-1"><a className="link-900 text-secondary text-decoration-none" aria-current="page" href="#intervenant">Collaboration</a></li>
                    
                  { 
                    user != false && (<>
                    <li className="mb-1"><a className="link-900 text-secondary text-decoration-none" href={ process.env.REACT_APP_EVIVESOMA + "/#profile" }>Suivi</a></li>
                    <li className="mb-1"><a className="link-900 text-secondary text-decoration-none" href={ process.env.REACT_APP_EVIVESOMA + "/rappels" }>Rappel</a></li>
                    </>
                    )
                  }
                  
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 mb-4 mb-lg-0 order-4 order-md-4 order-lg-3">
                
                
              </div>
              <div className="col-lg-3 col-md-6  mb-4 mb-lg-0 text-center">
                <p className="fs-2 mb-lg-4">Solution scolaire adaptable.</p>
                  { 
                    user == false && (
                      <button className="btn btn-primary btn-sm  me-3 btn-lg" type="button"  data-bs-toggle="modal" data-bs-target="#popupLogin" >S'authentifier</button>
                    )
                  }
                  { 
                    user != false && (
                      <button className="btn btn-danger btn-sm  me-3 btn-lg" type="button" onClick={() => this.logout()} >Déconnexion</button>
                    )
                  }
              </div>
            </div>
          </div>
  
        </section>
        )
    }
}
export default Design7;