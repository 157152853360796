import React, {Component} from "react";

class Design8 extends Component{

    render(){
        return(
          <section className="text-center py-0">

          <div className="container">
            <div className="container border-top py-3">
              <div className="row justify-content-between">
                <div className="col-12 col-md-auto mb-1 mb-md-0">
                  <p className="mb-0 fw-bolder">&copy;2022 { process.env.REACT_APP_NAME }</p>
                </div>
                <div className="col-12 col-md-auto">
                  <p className="mb-0">
                    Made<span className="fas fa-heart mx-1" style={{  color : "red" }}></span>by <a className="text-decoration-none ms-1 text-primary" href="#https://evivedigital.com" target="_blank">EviveDigital</a></p>
                </div>
              </div>
            </div>
          </div>
  
        </section>
        )
    }
}
export default Design8;